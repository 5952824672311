import React from 'react';
import SEO from "../common/SEO";

import Myheader from '../common/header/Myheader';
import Container23759 from '../assets/images/Container23759.mp4';
import Myfooter from '../common/footer/Myfooter';

import CounterUpFour from '../elements/counterup/CounterUpFour';



const Shipper = () => {
    
    return (
        <>
            <SEO title="About" />
            <main className="page-wrapper">
                
                <div className="header-transparent-with-topbar">
                    
                    <Myheader btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
                </div>
              

        {/* Start Slider Area  */}
           <section>
                <div className="container-fluid">
                    <div className="row ">       
                        <video src={Container23759}  autoPlay loop muted id="slidervideo">  </video> 
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row ">    
                        <div className="col-lg-8 col-xl-12">
                            <div className="inner text-right">
                                    <h1 className="title display-one">Moving You Towards<br />Stress- Free Trucking</h1>
                                    <h6 className="tag-title">SOLUTION FOR YOUR BUSINESS</h6>
                             </div>
                        </div>
                        
                    </div>
                </div>
            </section>          
        {/* End Slider Area  */}



    <section className='wh '>
         {/* Start Service Area  */}
         <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We are creative agency for company brands.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci. Veniam neque itaque expedita officiis rem ipsa! Ratione, rem reiciendis?</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
    </section>

    <img className="w-100" src=".//images/ast/transport-g1.jpg" alt="Solution"/>


           
                   
    <section className='rwt-counterup-area pb--100 rn-section-gapTop rn-section-gapBottom'>
        <div className="container">
        <div className="row">           

    <div className="col-md-4">
        <h5 className='title1'>Integrity</h5>
        <p className='whitepara'>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    <div className="col-md-4">
        <h5 className='title1'>Transparency</h5>
        <p className='whitepara'>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    <div className="col-md-4">
        <h5 className='title1'>Innovation</h5>
        <p className='whitepara'>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    </div>
    </div>
    </section>


    <section className='wh rn-section-gapTop rn-section-gapBottom'>
        <div className="containershort">
        <div className="row">           

    <div className="col-md-6">
        <h4 className='abttitle'>Our Mission</h4>
        <p>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>
    <div className="col-md-6">
    <img className="w-100" src=".//images/ast/mission.jpg" alt="Solution"/>
    </div>

    <div className="col-md-6 rn-section-gapTop">
        <img className="w-100" src=".//images/ast/how-img.jpg" alt="Solution"/> 
    </div>
    <div className="col-md-6 rn-section-gapTop">
        <h4 className='abttitle'>Our Vision</h4>
        <p>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    <div className="col-md-6 rn-section-gapTop">
        <h4 className='abttitle'>Our Passion</h4>
        <p>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>
    <div className="col-md-6 rn-section-gapTop">
    <img className="w-100" src=".//images/ast/passion.jpg" alt="Solution"/>
    </div>

    </div>
    </div>
    </section>



                {/* Start Elements Area  */}
                <section className="grey rn-section-gapTop rn-section-gapBottom">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </section>
                {/* End Elements Area  */}




                <Myfooter />
                
            </main>
        </>
        
    ) 
}


export default Shipper;
