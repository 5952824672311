import React from 'react';
import SEO from "../common/SEO";

import Myheaderlight from '../common/header/Myheaderlight';
import Myfooter from '../common/footer/Myfooter';
import Footernews from '../components/myimagebox/Footernews';

import ReactFullpage from '@fullpage/react-fullpage';

import {Link} from "react-router-dom";

import Cityform from '../components/myimagebox/Cityform';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiCheck } from "react-icons/fi";



const Oregon = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {1000} 
      scrollOverflow = {true}
      
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>



{/* Start internal banner */}
<div className="section">
    {/* Header  */}
    <SEO title="Oregon - OR" />   
            <div className="header-transparent-with-topbar">                   
                <Myheaderlight btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent navlight" />
            </div>
    {/* Header */} 
    {/* Start Slider Area  */}
    <div className="slider-area slider-style-6 shape-right height-920">
               <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-left">
                                    <h1 className="title color-white">Trending <br />Oregon - OR</h1>
                                    <p className="description color-white">We help our clients succeed by creating brand identities, digital experiences, and print materials.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="cali btn-default btn-medium btn-icon btn-border" target="_blank" href="#">View Loads<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/banenr-image-02.jpg" alt="Banner Images" />
                                        
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
        {/* End Slider Area  */}
</div>
{/* End internal banner */}    




{/* Start Elements Area 1 */}
<div className="section">   
        <section className="wh rn-section-tbpadding">
            <div className="container mx-auto">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                    <h3 className="title">Global custom security system</h3>
                       <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
                        <h5 className='subtitle'>As a trusted 3PL partner for shipping services, we get it done.</h5>
                            <p class="description">Based in Washington, USA, AST aims to create a reliable marketplace for carriers and provide transportation solutions for the leading supply chains. In order to do that you provide transportation solutions that delivers reliably and is also cost effective. Based in Washington, USA, AST aims to create a reliable marketplace for carriers and provide transportation solutions for the leading supply chains. In order to do that you provide transportation solutions that marketplace for carriers and provide transportation delivers reliably and is also cost effective.</p>
                        </div>
                        <div class="buttn">
                          <a class="button3" href="#">Driver Jobs</a>
                          <a class="button3" href="#">Get a Quote</a>  
                        </div>
                        
               </div>              
            </div>
            </div>
        </section>
    </div>
{/* End Elements Area 1 */}



{/* Start Elements Area 2 */}
<div className="section">  
        <section className="bl rn-section-benefits">
            <div className="container mx-auto">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                    <h3 className="titlewh">What are the key benefits to shipping</h3>
                </div>  
                    <div className="container clienttop">
                        <div className='row'>
                       <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <h4 className='client'>Better placement, higher acceptance rate</h4>
                        <p className='descriptionwh'>We don’t believe in the typical agency model of giving each team member 10+ accounts to manage. Your dedicated team at Lemonpie is only working on a few other accounts at any time.</p>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <h4 className='client'>More praise from hosts than anyone</h4>
                        <p className='descriptionwh'>We don’t believe in the typical agency model of giving each team member 10+ accounts to manage. Your dedicated team at Lemonpie is only working on a few other accounts at any time.</p>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <h4 className='client'>Client to manager acceptance rate ratio</h4>
                        <p className='descriptionwh'>We don’t believe in the typical agency model of giving each team member 10+ accounts to manage. Your dedicated team at Lemonpie is only working on a few other accounts at any time.</p>
                        </div>
                        </div>
                    </div>
                       <div class="buttn text-center">
                          <a class="button1" href="tel:+1- 800-577-3940">Call : +1- 800-577-3940</a>
                           
                       </div>          
            </div>
            </div>
        </section>
    </div>
{/* End Elements Area 2 */}


{/* Start Elements Area 3 */}
<div className="section">
<section className="wh rn-section-keypadding key-service">
                    <div className="container">
                    <div className="col-lg-12 col-xl-12 mx-auto">
                       <h3 className="title textcenter">Our Experience is at Glance</h3>
                    </div>

                  <div className="container mt_dec--30">
                    <div className='row phonepad'>
                  <div className="col-md-7">
                  <ul>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>
                        <li><span>Scalable</span> - Our solutions are designed with growth in mind.</li>
                        <li><span>Flexible</span> - Flexible solutions to compliment business model.</li>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>
                        <li><span>Scalable</span> - Our solutions are designed with growth in mind.</li>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>
                        <li><span>Flexible</span> - Flexible solutions to compliment business model.</li>
                    </ul>    
                  </div>
                  <div className="col-md-5 text-center">
                       <img className="phonesmall" src='./images/ast/phonee.png' alt="Corporate React Template" />  
                  </div>
                  </div>
                  </div>

                    </div>
</section>
</div>
 {/* End Elements Area 3 */}


{/* Start Contact Area 4 */}
<div className="section">
        <section className="yellow rn-section-ctaform">
        <div className="container">
            <div className="row ">
                <div className="col-lg-7">                                      
                <h3 className='title'>Let’s Build The Future of Freight Together</h3>
           <p className='description'>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
                </div>
                <div className="col-lg-5">
                    <Cityform />
                </div>
            </div>
        </div>
        </section>
    </div>
{/* End Contact Area 4 */}




{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}



          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
  
      


export default Oregon;
