import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            
            <li><Link to="/About">About</Link></li> 

            <li className="has-droupdown"><Link to="#">Our Services</Link>
                <ul className="submenu">
                   <li><Link to="/Dispatching">24*7 Dispatching</Link></li>
                    <li><Link to="/Invoicing">Invocing</Link></li>
                    <li><Link to="/Accounting">Accounting</Link></li>
                    <li><Link to="/Factoring">Factoring</Link></li>
                    <li><Link to="/Driver-hiring">Driver Hiring</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="#">Other Compliances</Link>
                <ul className="submenu">
                   <li><Link to="/Fuel-car">Fuel Car</Link></li>
                    <li><Link to="/Insurance">Insurance</Link></li>
                    <li><Link to="/Inhouse-account">Inhouse Account</Link></li>
                    <li><Link to="/Documentation">Documentation</Link></li>
                </ul>
            </li>


            <li className="with-megamenu"><Link to="#">Locations</Link>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-2 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/California">California - CA</Link></li>
                                    <li><Link to="/Oregon">Oregon - OR</Link></li>
                                    <li><Link to="/Washington">Washington - WA</Link></li>
                                    <li><Link to="/Nevada">Nevada - NV</Link></li>
                                    <li><Link to="/Montana">Montana - MT</Link></li>
                                    <li><Link to="/Idaho">Idaho - ID</Link></li>
                                    <li><Link to="/Wyoming">Wyoming - WY</Link></li>
                                    <li><Link to="/Utah">Utah - UT</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/Colorado">Colorado - CO</Link></li>
                                    <li><Link to="/New-mexico">New Mexico - NM</Link></li>
                                    <li><Link to="/Arizona">Arizona - AZ</Link></li>
                                    <li><Link to="/North-dakota">North Dakota - ND</Link></li>
                                    <li><Link to="/South-dakota">South Dakota - SD</Link></li>
                                    <li><Link to="/Nebraska">Nebraska - NE</Link></li>
                                    <li><Link to="/Kansas">Kansas - KS</Link></li>
                                    <li><Link to="/Oklahoma">Oklahoma - OK</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/Texas">Texas - TX</Link></li>
                                    <li><Link to="/Minnesota">Minnesota - MN</Link></li>
                                    <li><Link to="/Iowa">Iowa - IA</Link></li>
                                    <li><Link to="/Missouri">Missouri - MO</Link></li>
                                    <li><Link to="/Arkansas">Arkansas - AR</Link></li>
                                    <li><Link to="/Louisiana">Louisiana - LA</Link></li>
                                    <li><Link to="/Mississippi">Mississippi - MS</Link></li>
                                    <li><Link to="/Alabama">Alabama - AL</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/Wisconsin">Wisconsin - WI</Link></li>
                                    <li><Link to="/Illinois">Illinois - IL</Link></li>
                                    <li><Link to="/Michigan">Michigan - MI</Link></li>
                                    <li><Link to="/Indiana">Indiana - IN</Link></li>
                                    <li><Link to="/Ohio">Ohio - OH </Link></li>
                                    <li><Link to="/Kentucky">Kentucky - KY</Link></li>
                                    <li><Link to="Tennessee">Tennessee - TN</Link></li>
                                    <li><Link to="/West-virginia">West Virginia - WV</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/Virginia">Virginia - VA</Link></li>
                                    <li><Link to="/North-carolina">North Carolina - NC</Link></li>
                                    <li><Link to="/South-carolina">South Carolina - SC</Link></li>
                                    <li><Link to="/Georgia">Georgia - GA</Link></li>
                                    <li><Link to="/Florida">Florida - FL</Link></li>
                        {/*   <li><Link to="/Florida">Florida - FL <span className="rn-badge-card">Popular</span></Link></li>   */}
                                    <li><Link to="/Maryland">Maryland - MD</Link></li>
                                    <li><Link to="/Delaware">Delaware - DE</Link></li>
                                    <li><Link to="/New-jersey">New Jersey - NJ</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/Pennsylvania">Pennsylvania - PA </Link></li>
                                    <li><Link to="/New-york">New York - NY </Link></li>
                                    <li><Link to="/Vermont">Vermont - VT</Link></li>
                                    <li><Link to="/Massachusetts">Massachusetts - MA</Link></li>
                                    <li><Link to="/New-hampshire">New Hampshire - NH</Link></li>
                                    <li><Link to="/Connecticut">Connecticut - CT</Link></li>
                                    <li><Link to="/Rhode-island">Rhode Island - RI</Link></li>
                                    <li><Link to="/Maine">Maine - ME</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
 
            <li><Link to="/blog-list-sidebar">Blog</Link></li>
            <li><Link to="/Contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
