import React from "react";


 const Serviceform = () => {
  return (
    <section className="about1 section-padding serviceform">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12">
                 <form action="mail.php" method="POST">
                    <div className="form-group">
                        <input type="text" name="name" className="form-col" placeholder="Name" required />
                    </div>
                    <div className="form-group">
                        <input type="email" name="email" className="form-col" placeholder="Email Address" required />
                    </div>
                    <div className="form-group">
                        <input type="tel" name="phone" className="form-col" placeholder="Phone Number" required />
                    </div> 
                    <div className="form-group">
                        <textarea name="message" placeholder="Your Message" required rows={5} cols={5} />
                    </div> 
                    <div className="form-group">
                        <input type="submit" className="btn-city-submit" value="Submit Now" />
                    </div>
                </form>

            </div>
        </div>     
      </div>
    </section>
  );
};

export default Serviceform;