import React from 'react'

import ab from '../../assets/images/ab.mp4';
import globe from '../../assets/images/globe.mp4';
import { Link } from 'react-router-dom';

const Incredible = () => {
    return (
    


<section className="bannervideo">
         <video src={globe}  autoPlay loop muted />
         <div class="overlay">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 vidoverlaybanner">
            <div className='container'>
                <div className='row'>
               <div className='col-sm-12 col-md-7 col-lg-7 col-xl-6 banleft'>
                    <h2 className='bannerhead'>Your navigator in the <span className='yel'>world </span> of logistics  </h2>
                  {/*}  <Link to="tel:+1 206 257 2134" className="hw-btn hw-btn-border-black">Schedule a Call</Link>*/}

                </div>
                <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 banright'>
                </div>
            </div>     
            </div>
            </div>  
        </div>
    </section>	

    
    )
}
export default Incredible;