import React from 'react';
import SEO from "../common/SEO";

import Myheader from '../common/header/Myheader';
import Myfooter from '../common/footer/Myfooter';
import Footernews from '../components/myimagebox/Footernews';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactFullpage from '@fullpage/react-fullpage';


const Documentation = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {1000} 
      scrollOverflow = {true}
      
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>



{/* Start internal banner */}
<div className="section sectmobile">
    {/* Header  */}
    <SEO title="Documentation" />        
            <div className="header-transparent-with-topbar">                   
                <Myheader btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
            </div>
{/* Header */} 
        <div className="slider-area slider-internal bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/ast/cargotruck1.jpg)`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-9">
                            <h3 className="title textcenter">Documentation</h3>
                            <p className="description textcenter">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci. Veniam neque itaque expedita officiis rem ipsa! Ratione, rem reiciendis?</p>
                            <div className='textcenter'>
                               <a class="button2" href="#">Find Driver Jobs</a>
                            </div>
                        </div>
                         <div className="col-lg-7 col-md-3">  
                        </div>
                    </div>
                </div>
        </div>

</div>
{/* End internal banner */}    



{/* Start Elements Area 1  */} 
<div className="section">
<section className="wh rn-section-keypadding">
        <div className="container">
            <div className='row'>
                <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                    <h3 className="title">Benefits to make Legacy</h3>
                    <p className="description">Based in Washington, USA, AST aims to create a reliable marketplace for carriers and provide transportation solutions</p>
                </div>
            </div>

            <div className='row col-xl-9 col-lg-12 col-md-12 mx-auto'>
                <div className="col-md-4 text-center">
                <img className="iconsize" src=".//images/ast/icon2.gif" />
                    <div className="serv_text">Fun on-and-off site events</div>
                </div>

                <div className="col-md-4 text-center">
                    <img className="iconsize" src=".//images/ast/icon1.png" />
                    <div className="serv_text">Employee assistance program</div>
                </div>

                <div className="col-md-4 text-center">
                    <img className="iconsize" src=".//images/ast/icon1.png" />
                    <div className="serv_text">Active Rewards Program</div>
                </div>
            </div>


            <div className='row col-xl-9 col-lg-12 col-md-12 secondrow mx-auto'>
                <div className="col-md-4 text-center">
                    <img className="iconsize" src=".//images/ast/icon1.png" />
                    <div className="serv_text">Fun on-and-off site events</div>
                </div>

                <div className="col-md-4 text-center">
                    <img className="iconsize" src=".//images/ast/icon1.png" />
                    <div className="serv_text">Employee assistance program</div>
                </div>

                <div className="col-md-4 text-center">
                    <img className="iconsize" src=".//images/ast/icon1.png" />
                    <div className="serv_text">Active Rewards Program</div>
                </div>
            </div>
                             
        </div>
</section>
</div>
{/* End Elements Area 1  */}



{/* Start Elements Area 2 */}
<div className="section">
<section className="bl rn-section-every">
<div className="container">
<div className="row">
   <div className="col-xl-12 col-lg-12 col-md-12 mx-auto text-center">
        <h3 className="titlewh">Everything to make every run</h3>
         <p className="descriptionwh" b1>Based in Washington, USA, AST aims to create a reliable marketplace for carriers and provide transportation solutions</p>
     </div>             
        <div className="col-lg-12">
            <Tabs>
            <div className="row align-items-center">
<div className="col-lg-5">
<div class="row">
    <div class="col-md-12 col-md-offset-3">
        <form id="msform">
            <ul id="progressbar">
                <li class="active">Personal Details</li>
                <li>Social Profiles</li>
                <li>Account Setup</li>
            </ul>

            <fieldset>
                <h2 class="fs-title">Personal Details</h2>
                <h3 class="fs-subtitle">Tell us something more about you</h3>
                <input type="text" name="fname" placeholder="First Name"/>
                <input type="text" name="lname" placeholder="Last Name"/>
                <input type="text" name="phone" placeholder="Phone"/>
                <input type="button" name="next" class="next action-button" value="Next"/>
            </fieldset>
            <fieldset>
                <h2 class="fs-title">Social Profiles</h2>
                <h3 class="fs-subtitle">Your presence on the social network</h3>
                <input type="text" name="twitter" placeholder="Twitter"/>
                <input type="text" name="facebook" placeholder="Facebook"/>
                <input type="text" name="gplus" placeholder="Google Plus"/>
                <input type="button" name="previous" class="previous action-button-previous" value="Previous"/>
                <input type="button" name="next" class="next action-button" value="Next"/>
            </fieldset>
            <fieldset>
                <h2 class="fs-title">Create your account</h2>
                <h3 class="fs-subtitle">Fill in your credentials</h3>
                <input type="text" name="email" placeholder="Email"/>
                <input type="password" name="pass" placeholder="Password"/>
                <input type="password" name="cpass" placeholder="Confirm Password"/>
                <input type="button" name="previous" class="previous action-button-previous" value="Previous"/>
                <input type="submit" name="submit" class="submit action-button" value="Submit"/>
            </fieldset>
        </form>
    </div>
</div>
 </div>
        <div className="col-lg-7">
            <div className="rn-default-tab style-two">
                <div className="tab-button-panel">
                    <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Active rewards</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>On-site fitness center</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Training programs</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Bonuses</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <p className="descriptionwh">Lobn Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                    <p className="descriptionwh">Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <p className="descriptionwh">Wiki Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                    <p className="descriptionwh">Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <p className="descriptionwh">Emma Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                    <p className="descriptionwh">Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                     <p className="descriptionwh">Zutu Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                    <p className="descriptionwh">Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>           
                    </div>
                </div>
                </div>
</section>
</div>
{/* End Elements Area 2 */}



{/* Start Elements Area 3 */}  
<div className="section">
<section className="wh rn-section-keypadding key-service">
                    <div className="container">
                    <div className="col-lg-12 col-xl-12 mx-auto">
                       <h3 className="title textcenter">Our Experience is at Glance</h3>
                    </div>

                  <div className="container mt_dec--30">
                    <div className='row phonepad'>
                  <div className="col-md-7">
                  <ul>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>
                        <li><span>Scalable</span> - Our solutions are designed with growth in mind.</li>
                        <li><span>Flexible</span> - Flexible solutions to compliment business model.</li>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>
                        <li><span>Scalable</span> - Our solutions are designed with growth in mind.</li>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>
                        <li><span>Flexible</span> - Flexible solutions to compliment business model.</li>
                    </ul>    
                  </div>
                  <div className="col-md-5 text-center">
                       <img className="phonesmall" src='./images/ast/phonee.png' alt="Corporate React Template" />  
                  </div>
                  </div>
                  </div>

                    </div>
</section>
</div>
 {/* End Elements Area 3 */}



{/* Start Elements Area 4 */}
<div className="section">
<section className='yellow rn-section-truck'>
        <div className="container">
        <div className="row reversesection">     

        <div className="col-lg-8 col-md-8">
           <h3 className='title'>Let’s Build The Future of Freight Together Our Passion</h3>
           <p className='description'>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
           <a class="button2" href="#">Find Driver Jobs</a>
        </div>

        <div className="col-lg-4 col-md-4" id='technology'>
            <img className="ittruck" src=".//images/ast/disright.png" alt="Solution"/>
        </div>

        </div>
    </div>
    </section>
</div>
 {/* End Elements Area 4 */}



{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}



          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
  
      


export default Documentation;
