import React from 'react'
import $ from 'jquery';

$(document).ready(function(){
    $(".overDiv").hover(function(){
      $(this).find('.itemContentInfo').animate({height:215},300);
      $(this).find('.itemContentTitle').addClass('itemContentTitleHover');  
    },function(){
      $(this).find('.itemContentInfo').animate({height:0},300);
      $(this).find('.itemContentTitle').removeClass('itemContentTitleHover'); 
    });
  });   


  
const Imagebox = () => {
    return (
        
<section className="wh rn-section-topbottomcompl">
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
    <div className="container asttop">
         <div className="row">
                <h3 className="title">Other Carrier Complainces</h3>
        </div>
    </div>
    </div>

    <div className="container">
        <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="itemWrapper">
                <div className="itemContainer">

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="item clearfix">
                        <div className="itemContent">
                            <div className="itemContentItem">
                                <img src=".//images/bg/shipper_square.jpg" title="Ebook" alt="Ebook"/>
                            </div>
                        <div className="overDiv">
                            <div className="itemContentTitle">
                                <h2 class="ins">Insurance</h2>
                            </div>
                            <div className="itemContentInfo">
                                <p>Work with a provider that can reliably meet all of your logistics services needs today and in the future.</p>
                            </div>
                            <div className="itemContentDownload">
                            <a class="itemBtn" href="#">Read More</a>
                            </div>
                        </div>
                        </div>
                  </div> 
                </div>  
      
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="item clearfix">
                    <div className="itemContent">
                        <div className="itemContentItem">
                            <img src=".//images/bg/carriers_square.jpg" title="Ebook" alt="Ebook"/>
                        </div>
                        <div className="overDiv">
                            <div className="itemContentTitle">
                                <h2>In-House Accounting</h2>
                            </div>
                            <div className="itemContentInfo">
                                <p>No matter the type & volume of freight you want to move, AST has got you covered for your every shipping need.</p>
                            </div>
                            <div className="itemContentDownload">
                            <a class="itemBtn" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                  </div>  
                </div>  

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="item clearfix">
                    <div className="itemContent">
                        <div className="itemContentItem">
                        <img src=".//images/bg/shipper_square.jpg" title="Ebook" alt="Ebook"/>
                        </div>
                        <div className="overDiv">
                            <div className="itemContentTitle">
                                <h2>Documentation</h2>
                            </div>
                            <div className="itemContentInfo">
                                <p>Find the rewarding opportunities that will accelerate your career growth.</p>
                            </div>
                            <div className="itemContentDownload">
                            <a class="itemBtn" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                  </div>  
                </div>   
               
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="item clearfix">
                    <div className="itemContent">
                        <div className="itemContentItem">
                        <img src=".//images/bg/carriers_square.jpg" title="Ebook" alt="Ebook"/>
                        </div>
                        <div className="overDiv">
                            <div className="itemContentTitle">
                                <h2>Have A Question?</h2>
                            </div>
                            <div className="itemContentInfo">
                                <p>Got a question regarding our dispatching services? We’re here to answer them all.</p>
                            </div>
                            <div className="itemContentDownload">
                            <a class="itemBtn" href="#">Call Now</a>
                            </div>
                        </div>
                    </div>
                  </div>  
                </div>  

              </div>
          </div></div>
        </div> 
    </div>
      
</section>
      
    )
}
export default Imagebox;