import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Splash from "./pages/Splash";
import HomeDefault from "./pages/HomeDefault";
import BusinessConsulting2 from "./pages/BusinessConsulting2";
import Corporate from "./pages/Corporate";
import Business from "./pages/Business";
import DigitalAgency from "./pages/DigitalAgency";
import Finance from "./pages/Finance";
import Company from "./pages/Company";
import Marketing from "./pages/Marketing";
import TravelAgency from "./pages/TravelAgency";
import Consulting from "./pages/Consulting";
import SeoAgency from "./pages/SeoAgency";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import EventConference from "./pages/EventConference";
import CreativePortfolio from "./pages/CreativePortfolio";
import Freelancer from "./pages/Freelancer";
import InternationalConsulting from "./pages/InternationalConsulting";
import Startup from "./pages/Startup";
import WebAgency from "./pages/WebAgency";


import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";
import Author from "./pages/Author";
import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Elements from "./elements/elements/Elements";
import Button from "./elements/button/Button";
import Service from "./elements/service/Service";
import CounterUp from "./elements/counterup/CounterUp";
import Progressbar from "./elements/progressbar/Progressbar";

import Portfolio from "./elements/portfolio/Portfolio";
import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import PortfolioFullWidth from "./elements/portfolio/PortfolioFullWidth";
import PortfolioGrid from "./elements/portfolio/PortfolioGrid";
import PortfolioBoxLayout from "./elements/portfolio/PortfolioBoxLayout";
import PortfolioDetails from "./pages/PortfolioDetails";
import Social from "./elements/social/Social";
import Team from "./elements/team/Team";
import Timeline from "./elements/timeline/Timeline";
import Testimonial from "./elements/testimonial/Testimonial";
import RnAccordion from "./elements/accordion/RnAccordion";
import Tab from "./elements/tab/Tab";
import Pricing from "./elements/pricing/Pricing";
import Split from "./elements/split/Split";
import CalltoAction from "./elements/calltoaction/CalltoAction";
import Video from "./elements/video/Video";
import Gallery from "./elements/gallery/Gallery";
import Brand from "./elements/brand/Brand";
import AdvanceTab from "./elements/advancetab/AdvanceTab";
import AdvancePricing from "./elements/advancePricing/AdvancePricing";


import BlogGridView from "./components/blog/BlogGridView";
import BlogListView from "./components/blog/BlogListView";
import BlogGridSidebar from "./components/blog/BlogGridSidebar";
import BlogListSidebar from "./components/blog/BlogListSidebar";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";


import Myhome from "./pages/Myhome";
import Contactus from './pages/Contactus';
import Contact from './pages/Contact';
import About from './pages/About';
import Shipper from './pages/Shipper';
import Dispatching from './pages/Dispatching';

// Import Css Here 
import './assets/scss/style.scss';
import Invoicing from './pages/Invoicing';
import Accounting from './pages/Accounting';
import Factoring from './pages/Factoring';
import DriverHiring from './pages/Driver-hiring';
import Fuelcar from './pages/Fuelcar';
import Insurance from './pages/Insurance';
import InhouseAccount from './pages/Inhouse-account';
import Documentation from './pages/Documentation';
import California from './pages/California';
import Oregon from './pages/Oregon';
import Washington from './pages/Washington';
import Nevada from './pages/Nevada';
import Montana from './pages/Montana';
import Idaho from './pages/Idaho';
import Wyoming from './pages/Wyoming';
import Utah from './pages/Utah';
import Colorado from './pages/Colorado';
import NewMexico from './pages/New-mexico';
import Arizona from './pages/Arizona';
import NorthDakota from './pages/North-dakota';
import SouthDakota from './pages/SouthDakota';
import Nebraska from './pages/Nebraska';
import Kansas from './pages/Kansas';
import Oklahoma from './pages/Oklahoma';
import Arkansas from './pages/Arkansas';
import Missouri from './pages/Missouri';
import Iowa from './pages/Iowa';
import Minnesota from './pages/Minnesota';
import Texas from './pages/Texas';
import Mississippi from './pages/Mississippi';
import Louisiana from './pages/Louisiana';
import Alabama from './pages/Alabama';
import Wisconsin from './pages/Wisconsin';
import Illinois from './pages/Illinois';
import Michigan from './pages/Michigan';
import Indiana from './pages/Indiana';
import Ohio from './pages/Ohio';
import Kentucky from './pages/Kentucky';
import Tennessee from './pages/Tennessee';
import WestVirginia from './pages/WestVirginia';
import Virginia from './pages/Virginia';
import NorthCarolina from './pages/NorthCarolina';
import SouthCarolina from './pages/SouthCarolina';
import Georgia from './pages/Georgia';
import Florida from './pages/Florida';
import Maryland from './pages/Maryland';
import Delaware from './pages/Delaware';
import NewJersey from './pages/NewJersey';
import Pennsylvania from './pages/Pennsylvania';
import NewYork from './pages/NewYork';
import Vermont from './pages/Vermont';
import Massachusetts from './pages/Massachusetts';
import NewHampshire from './pages/NewHampshire';
import Connecticut from './pages/Connecticut';
import RhodeIsland from './pages/RhodeIsland';
import Maine from './pages/Maine';
import Carrier from './pages/Carrier';
import Thankyou from './pages/Thankyou';









const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Myhome}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Myhome"}`} exact component={Myhome}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business-consulting"}`} exact component={HomeDefault}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business-consulting-2"}`} exact component={BusinessConsulting2}/>
                    <Route path={`${process.env.PUBLIC_URL + "/corporate"}`} exact component={Corporate}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business"}`} exact component={Business}/>
                    <Route path={`${process.env.PUBLIC_URL + "/digital-agency"}`} exact component={DigitalAgency}/>
                    <Route path={`${process.env.PUBLIC_URL + "/finance"}`} exact component={Finance}/>
                    <Route path={`${process.env.PUBLIC_URL + "/company"}`} exact component={Company}/>
                    <Route path={`${process.env.PUBLIC_URL + "/marketing"}`} exact component={Marketing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/travel-agency"}`} exact component={TravelAgency}/>
                    <Route path={`${process.env.PUBLIC_URL + "/consulting"}`} exact component={Consulting}/>
                    <Route path={`${process.env.PUBLIC_URL + "/seo-agency"}`} exact component={SeoAgency}/>
                    <Route path={`${process.env.PUBLIC_URL + "/personal-portfolio"}`} exact component={PersonalPortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/event-conference"}`} exact component={EventConference}/>
                    <Route path={`${process.env.PUBLIC_URL + "/creative-portfolio"}`} exact component={CreativePortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/freelancer"}`} exact component={Freelancer}/>
                    <Route path={`${process.env.PUBLIC_URL + "/international-consulting"}`} exact component={InternationalConsulting}/>
                    <Route path={`${process.env.PUBLIC_URL + "/startup"}`} exact component={Startup}/>
                    <Route path={`${process.env.PUBLIC_URL + "/web-agency"}`} exact component={WebAgency}/>
                    
                    <Route path={`${process.env.PUBLIC_URL + "/Contactus"}`} exact component={Contactus}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/carrier"}`} exact component={Carrier}/>
                    <Route path={`${process.env.PUBLIC_URL + "/About"}`} exact component={About}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Shipper"}`} exact component={Shipper}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Thankyou"}`} exact component={Thankyou}/>

                    <Route path={`${process.env.PUBLIC_URL + "/Dispatching"}`} exact component={Dispatching}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Invoicing"}`} exact component={Invoicing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Accounting"}`} exact component={Accounting}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Factoring"}`} exact component={Factoring}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Driver-hiring"}`} exact component={DriverHiring}/>

                    <Route path={`${process.env.PUBLIC_URL + "/Fuel-car"}`} exact component={Fuelcar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Insurance"}`} exact component={Insurance}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Inhouse-account"}`} exact component={InhouseAccount}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Documentation"}`} exact component={Documentation}/>

                    {/* Cities  */}
                    <Route path={`${process.env.PUBLIC_URL + "/California"}`} exact component={California}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Oregon"}`} exact component={Oregon}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Washington"}`} exact component={Washington}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Nevada"}`} exact component={Nevada}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Montana"}`} exact component={Montana}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Idaho"}`} exact component={Idaho}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Wyoming"}`} exact component={Wyoming}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Utah"}`} exact component={Utah}/>


                    {/* Cities 2 */}
                    <Route path={`${process.env.PUBLIC_URL + "/Colorado"}`} exact component={Colorado}/>
                    <Route path={`${process.env.PUBLIC_URL + "/New-mexico"}`} exact component={NewMexico}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Arizona"}`} exact component={Arizona}/>
                    <Route path={`${process.env.PUBLIC_URL + "/North-dakota"}`} exact component={NorthDakota}/>
                    <Route path={`${process.env.PUBLIC_URL + "/South-dakota"}`} exact component={SouthDakota}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Nebraska"}`} exact component={Nebraska}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Kansas"}`} exact component={Kansas}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Oklahoma"}`} exact component={Oklahoma}/>


                    {/* Cities 3 */}
                    <Route path={`${process.env.PUBLIC_URL + "/Arkansas"}`} exact component={Arkansas}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Missouri"}`} exact component={Missouri}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Iowa"}`} exact component={Iowa}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Minnesota"}`} exact component={Minnesota}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Texas"}`} exact component={Texas}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Alabama"}`} exact component={Alabama}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Mississippi"}`} exact component={Mississippi}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Louisiana"}`} exact component={Louisiana}/>


                    {/* Cities 4 */}
                    <Route path={`${process.env.PUBLIC_URL + "/Wisconsin"}`} exact component={Wisconsin}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Illinois"}`} exact component={Illinois}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Michigan"}`} exact component={Michigan}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Indiana"}`} exact component={Indiana}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Ohio"}`} exact component={Ohio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Kentucky"}`} exact component={Kentucky}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Tennessee"}`} exact component={Tennessee}/>
                    <Route path={`${process.env.PUBLIC_URL + "/West-virginia"}`} exact component={WestVirginia}/>


                    {/* Cities 5 */}
                    <Route path={`${process.env.PUBLIC_URL + "/Virginia"}`} exact component={Virginia}/>
                    <Route path={`${process.env.PUBLIC_URL + "/North-carolina"}`} exact component={NorthCarolina}/>
                    <Route path={`${process.env.PUBLIC_URL + "/South-carolina"}`} exact component={SouthCarolina}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Georgia"}`} exact component={Georgia}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Florida"}`} exact component={Florida}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Maryland"}`} exact component={Maryland}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Delaware"}`} exact component={Delaware}/>
                    <Route path={`${process.env.PUBLIC_URL + "/New-jersey"}`} exact component={NewJersey}/>


                    {/* Cities 6 */}
                    <Route path={`${process.env.PUBLIC_URL + "/Pennsylvania"}`} exact component={Pennsylvania}/>
                    <Route path={`${process.env.PUBLIC_URL + "/New-york"}`} exact component={NewYork}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Vermont"}`} exact component={Vermont}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Massachusetts"}`} exact component={Massachusetts}/>
                    <Route path={`${process.env.PUBLIC_URL + "/New-hampshire"}`} exact component={NewHampshire}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Connecticut"}`} exact component={Connecticut}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Rhode-island"}`} exact component={RhodeIsland}/>
                    <Route path={`${process.env.PUBLIC_URL + "/Maine"}`} exact component={Maine}/>






                    {/* Blog Part  */}
                    <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} exact component={BlogGridView}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-list-view"}`} exact component={BlogListView}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-grid-sidebar"}`} exact component={BlogGridSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-list-sidebar"}`} exact component={BlogListSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} exact component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} exact component={CategoryList}/>
                    <Route path={`${process.env.PUBLIC_URL + "/archive/:slug"}`} exact component={Author}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} exact component={TagList}/>

                    <Route path={`${process.env.PUBLIC_URL + "/elements"}`} exact component={Elements}/>
                    <Route path={`${process.env.PUBLIC_URL + "/button"}`} exact component={Button}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`} exact component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + "/counter"}`} exact component={CounterUp}/>
                    <Route path={`${process.env.PUBLIC_URL + "/progressbar"}`} exact component={Progressbar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/social-share"}`} exact component={Social}/>
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`} exact component={Team}/>
                    <Route path={`${process.env.PUBLIC_URL + "/testimonial"}`} exact component={Testimonial}/>
                    <Route path={`${process.env.PUBLIC_URL + "/timeline"}`} exact component={Timeline}/>
                    <Route path={`${process.env.PUBLIC_URL + "/accordion"}`} exact component={RnAccordion}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tab"}`} exact component={Tab}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pricing"}`} exact component={Pricing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/split"}`} exact component={Split}/>
                    <Route path={`${process.env.PUBLIC_URL + "/call-to-action"}`} exact component={CalltoAction}/>
                    <Route path={`${process.env.PUBLIC_URL + "/video-popup"}`} exact component={Video}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/brand"}`} exact component={Brand}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-tab"}`} exact component={AdvanceTab}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-pricing"}`} exact component={AdvancePricing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>

                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio"}`} exact component={Portfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-three-column"}`} exact component={portfolioThreeColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-full-width"}`} exact component={PortfolioFullWidth}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-layout"}`} exact component={PortfolioGrid}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-box-layout"}`} exact component={PortfolioBoxLayout}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`} exact component={PortfolioDetails}/>


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
