import React from "react";


 const Cityform = () => {
  return (
    <section className="about1 section-padding">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12">

                 <form action="citymail.php" method="POST">
                    <div className="form-group">
                        <input type="text" name="fname" className="form-col" placeholder="First Name" required />
                    </div>
                    <div className="form-group">
                        <input type="text" name="lname" className="form-col" placeholder="Last Name" />
                    </div>
                    <div className="form-group">
                        <input type="email" name="email" className="form-col" placeholder="Email Address" required />
                    </div>
                    <div className="form-group">
                        <input type="tel" name="phone" className="form-col" placeholder="Phone Number" required />
                    </div>  
                    <div className="form-group">
                        <input type="submit" className="btn-city-submit" value="Submit Now" />
                    </div>
                </form>

            </div>
        </div>     
      </div>
    </section>
  );
};

export default Cityform;