import React from 'react'
import $ from 'jquery';

$(document).ready(function(){
    $(".overDiv").hover(function(){
      $(this).find('.itemContentInfo').animate({height:215},300);
      $(this).find('.itemContentTitle').addClass('itemContentTitleHover');  
    },function(){
      $(this).find('.itemContentInfo').animate({height:0},300);
      $(this).find('.itemContentTitle').removeClass('itemContentTitleHover'); 
    });
  });   


  
const Threethings = () => {
    return (
        
<section className="wh rn-section-keypadding">
        <div className="container">
            <div className='row'>
                <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                    <h3 className="title">Three things we are damm good at</h3>
                    <p className="description">Based in Washington, USA, AST aims to create a reliable marketplace for carriers and provide transportation solutions</p>
                </div>
            </div>

            <div className='row col-xl-12 col-lg-12 col-md-12 threebox mx-auto'>
                <div className="col-md-4 three1 text-center">
                <img className="iconsize1" src=".//images/ast/one.png" />
                    <div className="serv_text1">Conveying your message</div>
                    <p>We provide best-in-class transportation solutions for the leading supply chains</p>
                </div>

                <div className="col-md-4 three2 text-center">
                    <img className="iconsize1" src=".//images/ast/two.png" />
                    <div className="serv_text1">Employee assistance program</div>
                    <p>We provide best-in-class transportation solutions for the leading supply chains</p>
                </div>

                <div className="col-md-4 three2 text-center">
                    <img className="iconsize1" src=".//images/ast/three.png" />
                    <div className="serv_text1">Active Rewards Program</div>
                    <p>We provide best-in-class transportation solutions for the leading supply chains</p>
                </div>
            </div>
                             
        </div>
</section>
      
    )
}
export default Threethings;