import React from 'react'



const Leftrightsection = () => {
    return (
        
<section className="wh logooverflow rn-section-topbottomlogo">
    <div className="container-fluid clearfix skew-blade desktop">
            <div className="left-skew">   
                <div className="skew-copy">
                    <div className="contentlogo">
                        <img src="..//images/bg/astlogo.png" className="skewlogo" alt="AST Logo" />
                    </div>
                </div>
            </div>
            <div className="right-skew">
                  <img src="..//images/bg/right1.jpg" />
                <div className="skew-copy">
                        <h4>Your guide to global logistics in Canada</h4>
                        <p>Take a moment to learn about our capabilities and how we can customize them to fit your needs.</p>   
                        <a href="#" className="btn">Explore Platform Now</a>
                </div>
            </div>     
    </div>

    <div className="container-fluid clearfix skew-blade mobile">
            <div className="left-skew">
                <div className="skew-copy">
                    <div className="contentlogo">
                        <img src="..//images/bg/astlogo.png" className="skewlogo" alt="AST Logo" />
                    </div>
                </div>
            </div>
            <div className="right-skew" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/rightmob1.jpg)`}}> 
                <div className="skew-copy1">
                        <h4>Your guide to global logistics in Canada</h4>
                        <p>Take a moment to learn about our capabilities and how we can customize them to fit your needs.</p>   
                        <a href="#" className="btn">Explore Platform Now</a>
                </div>
            </div>     
    </div>

</section>


    )
}
export default Leftrightsection;