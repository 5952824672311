import React from 'react';
import SEO from "../common/SEO";

import Myheader from '../common/header/Myheader';
import Myfooter from '../common/footer/Myfooter';
import Footernews from '../components/myimagebox/Footernews';

import ReactFullpage from '@fullpage/react-fullpage';
import CounterUpFour from '../elements/counterup/CounterUpFour';

import Yellowsection from '../components/myimagebox/Yellowsection';
import Carrierform from '../components/myimagebox/Carrierform';


const Carrier = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {800} /* Options here */
  
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>


{/* Start internal banner */}
    <div className="section dispmobile">
        {/* Header  */}
        <SEO title="Carrier" />            
            <div className="header-transparent-with-topbar">                   
                <Myheader btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
            </div>
        {/* Header */} 
        <div className="slider-area slider-style-about bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-sky.png)`}}>
                <div className="container pb-60">
                    <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <h3 className="title textcenter">Carrier form</h3>
                            </div>
                            <div className="col-lg-6 col-lg-6">
                                <p className="abtpara textcenter">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci.</p>
                            </div>                           
                    </div>
                </div>
                <img className="w-100" src=".//images/ast/about2.png" alt="Solution"/>                
        </div>       
    </div>
{/* End internal banner */}



{/* Start Contact Area 2 */}
<div className="section">
        <section className="wh rn-section-formpadding">
        <div className="container">
            <div className="row ">
                <div className="col-lg-12">
                    <Carrierform />
                </div>
            </div>
        </div>
        </section>
    </div>
{/* End Contact Area 2 */}




{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}



          </ReactFullpage.Wrapper>
        );
      }}
    />
  );

export default Carrier;
