import React from 'react'
import SEO from "../common/SEO";

import right from '../assets/images/right.png';

import Myheaderlight from '../common/header/Myheaderlight';
import Myfooter from '../common/footer/Myfooter';
import Footernews from '../components/myimagebox/Footernews';

const Thankyou = () => {
    
    return (
        <>

{/* Header  */}
    <SEO title="Thank you" />   
            <div className="header-transparent-with-topbar">                   
                <Myheaderlight btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent navlight" />
            </div>
{/* Header */} 

            <main className="page-wrapper">
           <section className='thankyou'>
           <div className='container'>
            <div className='row'>
            <div className='col-md-12'>
                <div className='thankyoubox'>
                <img src={right} />
                <h3>Email Invitation Confirmation</h3>
                <p class="updates">Thank you. We are manually evaluating your request to register. Please allow time for processing. <br></br>
                If approved, you will receive an email invitation from <a href="mailto:dispatch@ast.com">dispatch@ast.com</a> to <br></br>complete the registration process.</p>
               
                <h5>THE INVITE TO COMPLETE THE PROCESS WILL BE SENT TO YOUR EMAIL</h5>
                <p class="updates">For updates on your evaluation to onboard, please email <a href="mailto:dispatch@ast.coms">dispatch@ast.com</a> or call <a href="tel:+1- 800-577-3940">+1- 800-577-3940</a>. <br></br>
                Again, please allow a few minutes for processing and check your email before <br></br>reaching out for an update.</p>
                </div>
            </div>
            </div>
           </div>
           </section>

            </main>

{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}


        </>
    )
}
export default Thankyou

