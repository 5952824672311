import React from 'react';
import { FaSistrix } from "react-icons/fa";
import SEO from "../../common/SEO";

import Myheader from '../../common/header/Myheader';
import Myfooter from '../../common/footer/Myfooter';
import Footernews from '../../components/myimagebox/Footernews';

import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogPropTwo from './itemProp/BlogPropTwo';
import SideCategories from './sidebar/SideCategories';
import SidebarTag from './sidebar/SidebarTag';

import ReactFullpage from '@fullpage/react-fullpage';


const BlogListSidebar = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {1000} /* Options here */
  
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>



 {/* Start internal banner */}
        <div className="section">
            {/* Header  */}
            <SEO title="Insurance" />    
            <div className="header-transparent-with-topbar">                   
                <Myheader btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
            </div>
            {/* Header */} 

        <div className="breadcr mr-100 bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-sky.png)`}}>
                <BreadcrumbOne  
                    title="The Easiest Way To Blogs"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog List Sidebar"
                />


                <div className="bg_image">
                <section class="aboutpadding">     
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area">
                        <div className="container">
                            <div className="row row--30">
                                <div className="col-lg-8">
                                    <div className="row mt_dec--30">
                                        <BlogPropTwo column="col-lg-12 mt--30" StyleVarProp="box-card-style-default card-list-view" />
                                    </div>
                                </div>
                                <div className="col-lg-4 mt_md--40 mt_sm--40">
                                    <aside className="rwt-sidebar">

                                        <div className="rbt-single-widget widget_search mt--40">
                                            <div className="inner">
                                                <form className="blog-search" action="#">
                                                    <input type="text" placeholder="Search ..." />
                                                    <button className="search-button"><FaSistrix /></button>
                                                </form>
                                            </div>
                                        </div>

                                        {/* Start Single Widget  */}
                                        <div className="rbt-single-widget widget_categories mt--40">
                                            <h3 className="title">Categories</h3>
                                            <div className="inner">
                                                <SideCategories />
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}                              

                                        {/* Start Single Widget  */}
                                        <div className="rbt-single-widget widget_tag_cloud mt--40">
                                            <h3 className="title">Tags</h3>
                                            <div className="inner mt--20">
                                                <SidebarTag />
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}


                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>
                </section>
                </div>


                </div>
        </div>
{/* End internal banner */}





{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}


          </ReactFullpage.Wrapper>
        );
      }}
    />
  );

export default BlogListSidebar
