import React from "react";


 const Innerform = () => {
  return (
    <section className="about1 section-padding">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <h3 className="title textcenter_inq">Send Your Inquires</h3>

                 <form action="mail.php" method="POST">
                    <div className="form-group">
                        <input type="text" name="name" placeholder="Your Name" required />
                    </div>
                    <div className="form-group">
                        <input type="email" name="email" placeholder="Email Address" required />
                    </div>
                    <div className="form-group">
                        <input type="tel" name="phone" placeholder="Phone Number" required />
                    </div>
                    <div className="form-group">
                        <textarea name="message" placeholder="Your Message" required rows={5} cols={5} />
                    </div>
                    <div className="form-group">
                        <input type="submit" className="btn-submit" value="Submit Now" />
                    </div>
                </form>

            </div>
        </div>     
      </div>
    </section>
  );
};

export default Innerform;