import React from 'react'
import $ from 'jquery';

$(".zoomWrapper img").click(function(){
    $(this).toggleClass("flasher");
  });


const Shipper = () => {
    return (
        <>

{/* Start Service Area  */}
<section>
                <div className="service-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                    <h3 className="title">24*7 Dispatching</h3>
                                    <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci. Veniam neque itaque expedita officiis rem ipsa! Ratione, rem reiciendis?</p>
                                    <a class="button3" href="#">Find Driver Jobs</a>
                            </div>
                            <div className="col-lg-7">
      
                            </div>
                        </div>
                    </div>
                </div>
 </section>
{/* End Service Area  */}


  

            
            
        </>
    )
}
export default Shipper;