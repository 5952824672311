import React from 'react'


const Steps = () => {
    return (
        
  
<section className='bl rn-section-topbottomsteps'>
    <div className='container'>
    <div className='row'>

    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
        <h3 className="titlewh">Your Gateway To Smart Trucking</h3>
        <div class="col-sm-12 col-md-12 col-lg-11 col-xl-9 mx-auto">
            <p className="descriptionwh" b1>Based in Washington, USA, American Smart Trucking aims to create a reliable marketplace for carriers and provide best-in-class transportation solutions for the leading supply chains</p>
         </div>
    </div>
  


<div className="column small-10 medium-8 large-12 small-offset-2 medium-offset-4 large-offset-0">
        <div className="home-solutions-slider arrows-on-hover slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track" style={{opacity: 1, height: '380px', width: '25000px', transform: 'translate3d(0px, 0px, 0px)'}}><div className="solution text-center solution-matchHeight slick-slide slick-current slick-active" style={{height: '400px'}} tabIndex={0} data-slick-index={0} aria-hidden="false">
                <div className="solution-circle-wrapper aos-init aos-animate" data-aos="fade-down" data-aos-easing="ease-in-shine" data-aos-duration={800}>
                  <a href="#" className="solution-circle" tabIndex={0}>
                    <img src=".//images/ast/icn_drayage.png" alt="Order Management" className="solution-icon lazyloaded" data-ll-status="loaded" /><noscript>&lt;img src="https://www.apllogistics.com/wp-content/uploads/2019/03/order-managment.svg"
                      alt="Order Management"
                      class="solution-icon"&gt;</noscript>
                  </a>
                </div>


                <h4 className="solution-title" style={{height: '40.7812px'}}>
                  <a href="#" tabIndex={0}>24*7 Dispatch<br />
                  </a>
                </h4>
                <p>Finding all top paying loads, negotiating rates, emails, contracts, and billing </p>
                <a href="#" className="solution-bottom-link learn-more-arrow learn-more-arrow-gray learn-more-center" tabIndex={0} />
              </div><div className="solution text-center solution-matchHeight slick-slide" tabIndex={-1} data-slick-index={1} aria-hidden="true">
                <div className="solution-circle-wrapper aos-init aos-animate" data-aos="fade-down" data-aos-easing="ease-in-shine" data-aos-duration={800}>
                  <a href="#" className="solution-circle" tabIndex={-1}>
                  <img src=".//images/ast/icn_full_truckIload.png" alt="Distribution & Fulfillment" className="solution-icon lazyloaded" data-ll-status="loaded" /><noscript>&lt;img src="https://www.apllogistics.com/wp-content/uploads/2019/03/distribution-fulfillment-1.svg"
                      alt="Distribution &amp; Fulfillment"
                      class="solution-icon"&gt;</noscript>
                  </a>
                </div>

                <h4 className="solution-title" style={{height: '40.7812px'}}>
                  <a href="#" tabIndex={-1}>Invoicing</a>
                </h4>
                <p>Handles all carrier packets, broker setups, and all aspects of paperwork</p>
                <a href="#" className="solution-bottom-link learn-more-arrow learn-more-arrow-gray learn-more-center" tabIndex={-1} />
              </div><div className="solution text-center solution-matchHeight slick-slide" tabIndex={-1} data-slick-index={2} aria-hidden="true">
                <div className="solution-circle-wrapper aos-init aos-animate" data-aos="fade-down" data-aos-easing="ease-in-shine" data-aos-duration={800}>
                  <a href="#" className="solution-circle" tabIndex={-1}>
                  <img src=".//images/ast/icn_intermodal.png" alt="Transportation" className="solution-icon lazyloaded" data-ll-status="loaded" /><noscript>&lt;img src="https://www.apllogistics.com/wp-content/uploads/2019/03/transportation.svg"
                      alt="Transportation"
                      class="solution-icon"&gt;</noscript>
                  </a>
                </div>

                <h4 className="solution-title" style={{height: '40.7812px'}}>
                  <a href="#" tabIndex={-1}>Accounting</a>
                </h4>
                <p>Our solutions help you obtain fuel cards and cash for on-the-road expenses</p>
                <a href="#" className="solution-bottom-link learn-more-arrow learn-more-arrow-gray learn-more-center" tabIndex={-1} />
              </div><div className="solution text-center solution-matchHeight slick-slide" tabIndex={-1} data-slick-index={3} aria-hidden="true">
                <div className="solution-circle-wrapper aos-init aos-animate" data-aos="fade-down" data-aos-easing="ease-in-shine" data-aos-duration={800}>
                  <a href="#" className="solution-circle" tabIndex={-1}>
                  <img src=".//images/ast/icn_less_than_truckload.png" alt="Customs Brokerage & Global Trade" className="solution-icon lazyloaded" data-ll-status="loaded" /><noscript>&lt;img src="https://www.apllogistics.com/wp-content/uploads/2019/03/customs-brokerage-global-trade-1.svg"
                      alt="Customs Brokerage &amp; Global Trade"
                      class="solution-icon"&gt;</noscript>
                  </a>
                </div>

                <h4 className="solution-title" style={{height: '40.7812px'}}>
                  <a href="#" tabIndex={-1}>Factoring</a>
                </h4>
                <p>Gain a complete edge with our highly specialized Trade Compliance Team</p>
                <a href="#" className="solution-bottom-link learn-more-arrow learn-more-arrow-gray learn-more-center" tabIndex={-1} />
              </div><div className="solution text-center solution-matchHeight slick-slide" tabIndex={-1} data-slick-index={4} aria-hidden="true">
                <div className="solution-circle-wrapper aos-init aos-animate" data-aos="fade-down" data-aos-easing="ease-in-shine" data-aos-duration={800}>
                  <a href="#" className="solution-circle" tabIndex={-1}>
                  <img src=".//images/ast/icn_warehousing.png" alt="Visibility & Analytics" className="solution-icon lazyloaded" data-ll-status="loaded" /><noscript>&lt;img src="https://www.apllogistics.com/wp-content/uploads/2019/03/data-management-analytics-1.svg"
                      alt="Visibility &amp; Analytics"
                      class="solution-icon"&gt;</noscript>
                  </a>
                </div>

                <h4 className="solution-title" style={{height: '40.7812px'}}>
                  <a href="#s" tabIndex={-1}>Driver Hiring</a>
                </h4>
                <p>We submit your invoices to your factoring company to get loads timely payments</p>
                <a href="https://www.apllogistics.com/solution/visibility-analytics" className="solution-bottom-link learn-more-arrow learn-more-arrow-gray learn-more-center" tabIndex={-1} />
              </div></div></div></div>
      </div>

      </div></div>

      {/*  <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        title = "Solutions That Take The Stress Out Of The Move"
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--50">
                                <TimelineTwo classVar="dark-line" />
                            </div>
                        </div>
                    </div>
    </div>*/}

      </section>  




    )
}
export default Steps;