import React from 'react'



const Footernews = () => {
    return (
        
<section className='blogsection'>
<div class="wrapper1">
<div class="container">

  <header class="header-box">
    <div class="page-header">
      <h2>Latest News</h2>
      <p class="lead">Check out the latest news, sport, entertainment, art and science from around the world!</p>
      <a href="http://localhost:3005/blog-list-sidebar" class="label label-primary view-all">View All</a>
    </div>
  </header>

  <div class="row post-card">


    <section class="col-sm-5bx footr">
      <a class="thumbnail" href="#">

      <div class="placeholder">
         <img src=".//images/bg/blog1.jpg"/>
      </div>

        <div class="caption">
          <h4 class="caption-heading">Good News About Coffee</h4>
         
        </div>
      </a>
    </section>


    <section class="col-sm-5bx col-xs-6 footr">
      <a class="thumbnail" href="#">
      <div class="placeholder">
         <img src=".//images/bg/blog2.jpg"/>
      </div>
        <div class="caption">
          <h4 class="caption-heading">Tips and Advice for Mind</h4>
          
        </div>
      </a>
    </section>


    <section class="col-sm-5bx col-xs-6 footr">
      <a class="thumbnail" href="#">
      <div class="placeholder">
         <img src=".//images/bg/blog3.jpg"/>
      </div>
        <div class="caption">
          <h4 class="caption-heading">Extend Yourself to Live</h4>
          
        </div>
      </a>
    </section>


    <section class="col-sm-5bx col-xs-6 footr">
      <a class="thumbnail" href="#">
      <div class="placeholder">
         <img src=".//images/bg/blog4.jpg"/>
      </div>
        <div class="caption">
          <h4 class="caption-heading">Guide to traveling</h4>
          
        </div>
      </a>
    </section>


    <section class="col-sm-5bx col-xs-6 footr">
      <a class="thumbnail" href="#">
      <div class="placeholder">
         <img src=".//images/bg/blog5.jpg"/>
      </div>
        <div class="caption">
          <h4 class="caption-heading">What We Salvage</h4>
         
        </div>
      </a>
    </section>

  </div>

</div>

</div>
</section>




    
    )
}
export default Footernews;