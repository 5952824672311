import React from 'react';
import SEO from "../common/SEO";

import Myheader from '../common/header/Myheader';
import Container23759 from '../assets/images/Container23759.mp4';
import Myfooter from '../common/footer/Myfooter';

import Imagebox from '../components/myimagebox/Imagebox';
import Leftrightsection from '../components/myimagebox/Leftrightsection';
import Incredible from '../components/myimagebox/Incredible';
import Carriershipper from '../components/myimagebox/Carriershipper';
import Map from '../components/myimagebox/Map';
import Steps from '../components/myimagebox/Steps';
import Yellowsection from '../components/myimagebox/Yellowsection';
import Footernews from '../components/myimagebox/Footernews';

import ReactFullpage from '@fullpage/react-fullpage';
import Myheaderlight from '../common/header/Myheaderlight';
import Threethings from '../components/myimagebox/Threethings';

import load from '../assets/images/load.mp4';
import dash from '../assets/images/dash.mp4';



const Myhome = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {1000} /* Options here */
  
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>



{/* Start Elements Area 1 */}
        <div className="section secmobile">
            {/* Header  */}
                <SEO title="AST" />       
                <div className="header-transparent-with-topbar toptrans">                   
                <Myheaderlight btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent navlight" />
                </div>
            {/* Header */} 

            <Incredible />
        </div>
{/* End Elements Area 1 */}


{/* Start Elements Area 5 */}
<div className="section">
            <Threethings />
        </div>
{/* End Elements Area 5 */}


{/* Start Elements Area 2 */}
        <div className="section">
            <Steps />
        </div>
{/* End Elements Area 2 */}
 
 {/* Start Elements Area 3 */}
 <div className="section">
        <section className="wh rn-section-keypadding key-service">
                    <div className="container">
                    <div className="col-lg-12 col-xl-12 mx-auto">
                       <h3 className="title textcenter">Focus on running business</h3>
                    </div>

                  <div className="container mt_dec--30">
                    <div className='row phonepad-front'>
                  <div className="col-md-6">
                  <ul>
                        <li><span>E-load confirmations</span> - Reduction in inventory and risk.</li>
                        <li><span>Real-time reports & workflow</span> - Our solutions are designed.</li>
                        <li><span>Order management</span> - Flexible solutions to business model.</li>
                        <li><span>Load planning</span> - Reduction in inventory and risk.</li>
                        <li><span>Invoicing & accounting</span> - Our solutions are growth in mind.</li>
                        <li><span>24*7 support</span> - Flexible solutions business model.</li>
                        <li><span>Optimisation</span> - Reduction in inventory and risk.</li>                     
                    </ul>    
                  </div>

                      <div className="col-md-6 text-center">
                      <div className="maptitle-col laptopvideo"> 
                            <video src={dash}  autoPlay loop muted />
                      </div>
                     </div>

                  </div>
                  </div>

                    </div>
        </section>
    </div>
{/* End Elements Area 3 */}


{/* Start Elements Area 4 */}
        <div className="section">
            <Leftrightsection />
        </div>
{/* End Elements Area 4 */}
 

 
{/* Start Elements Area 5 */}
<   div className="section">
            <Map />
    </div>
{/* End Elements Area 5 */}

{/* Start Elements Area 6 */}
<div className="section">
<section className='yellow rn-section-truck'>
        <div className="container">
        <div className="row reversesection">     

        <div className="col-lg-8 col-md-8">
           <h3 className='title'>Let’s Build The Future of Freight Together Our Passion</h3>
           <p className='description'>We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
           <a class="button2" href="#">Find Driver Jobs</a>
        </div>

        <div className="col-lg-4 col-md-4" id='technology'>
            <img className="ittruck" src=".//images/ast/disright.png" alt="Solution"/>
        </div>

        </div>
    </div>
    </section>
</div>
 {/* End Elements Area 6 */}


{/* Footer */}
    <div className="section bl">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}


          </ReactFullpage.Wrapper>
        );
      }}
    />
);



export default Myhome;
