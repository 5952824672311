import React from "react";


 const Carrierform = () => {
  return (
    <section className="about1 section-padding">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <h3 className="title text-center textcenter_inq">Send Your Inquires</h3>


{/* MultiStep Form */}
  <section className='carrierbnner' >
  <div className='container-fluid'>
    <div className='row'>
<div className='col-md-12 col-xxl-8 col-xl-8 col-12' id="registering">
 <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
 <div className="forward">

        <form id="msform" action="carriermail.php" method="post" encType="multipart/form-data">
          {/* progressbar */}
          <ul id="progressbar">
            <li className="active" id="account">
              <strong>Company Details</strong>
            </li>
            <li id="personal">
              <strong>Personal Details</strong>
            </li>
            <li id="payment">
              <strong>Billing Details</strong>
            </li>
            <li id="confirm">
              <strong>Attachments</strong>
            </li>
          </ul>

          <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin={0} aria-valuemax={100}/>
          </div>{" "}
          <br /> 

          {/* fieldset 1 */}
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Account Information:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 1</h2>
                </div>
              </div>
              <label className="fieldlabels">Company Name:</label>
              <input type="text" name="companyname" required />
              <h2 className="fs-title">MC/DOT Number*</h2>
              <div className="radio-group">
                <ul classname="DOTNumbe">
                  <li>
                    <input type="radio" name="dotnumber" id="DOT" defaultValue="DOT"/> DOT </li>
                  <li>
                    <input type="radio" name="mcumber" id="MC" defaultValue="MC" /> MC </li>
                </ul>
              </div>
              <label className="fieldlabels">MC/DOT Number:*</label>
              <input type="text" name="mcdotnumber" />
            </div>
            <input type="button" name="next" className="next action-button" defaultValue="Next" />
          </fieldset>

        {/* fieldset 2 */}
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Personal Details:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 2</h2>
                </div>
              </div>
              <label className="fieldlabels">First Name: *</label>
              <input type="text" name="firstname" required />
              <label className="fieldlabels">Last Name: *</label>
              <input type="text" name="lastname" required />
              <label className="fieldlabels">Contact No.: *</label>
              <input type="tel" name="phone" required />
              <label className="fieldlabels">Email: *</label>
              <input type="email" name="email" required />
            </div>
           
            <input type="button" name="next" className="next action-button" defaultValue="Next" />
            <input type="button" name="previous" className="previous action-button-previous" defaultValue="Previous"/>
          </fieldset>

        {/* fieldset 3 */}
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Billing Details:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 3 </h2>
                </div>
                </div>
                <div className="form-card">
                  <h2 className="fs-title">What kind of billing details you have?</h2>
   <div>
    {/*dropdown list options*/}
    <select name="billingtype" id="cars">
        <option>-- Choose billing type --</option>
        <option value="red" name="notfactoring">Not Factoring</option>
        <option value="green" name="factoring">Factoring</option>    
    </select>
   </div>

  {/*divs that hide and show*/}
  <div className="red box">
  <h2 className="fs-title">Please Fill Your Bank Details:</h2>
  <div className="row">
  <div className="col-6">
  <input type="text" name="name" placeholder="Name" />
  </div>
  <div className="col-6">
 <input type="number" id="routingnumber" name="routingnumber" />
  </div>
  </div>
  <div className="row">
  <div className="col-6">
  <input type="text" name="bank" />
  </div>
  <div className="col-6">
  <input type="number" id="accountnumber" name="accountnumber" />
  </div>
  </div>
  <input type="text" name="bankaddress" placeholder="Bank Address*" />
  </div>
 
        </div>
  </div>
        <input type="button" name="next" className="next action-button" defaultValue="Next"/>
        <input type="button" name="previous" className="previous action-button-previous" defaultValue="Previous"/>
        </fieldset>

        {/* fieldset 4 */}
        <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Image Documents:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 4</h2>
                </div>
              </div>
              <label className="fieldlabels">Insurance, MC Authority*, W9 Form*, NOA / Void Check*</label>
              <input type="file" name="file" multiple required/>
            
            </div>
            <input type="submit"  value="Submit" /> 
        </fieldset>
  
        </form>
      </div>
      </div>
</div>
    </div>
    </div>
 </section>
{/* /.MultiStep Form */}


            </div>
        </div>     
      </div>
    </section>
  );
};

export default Carrierform;