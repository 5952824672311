import React from 'react';
import SEO from "../common/SEO";
import ReactFullpage from '@fullpage/react-fullpage';

import Myheader from '../common/header/Myheader';
import Myfooter from '../common/footer/Myfooter';

import ContactForm from '../elements/contact/ContactForm';
import GoogleMapStyle from '../elements/contact/GoogleMapStyle';
import ContactOne from '../elements/contact/ContactOne';
import Innerform from '../components/myimagebox/Innerform';

import Footernews from '../components/myimagebox/Footernews';


const Contact = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {1000} /* Options here */
  
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>



{/* Start Contact Area 1 */}
    <div className="section">
        {/* Header  */}
        <SEO title="Contact Us" />        
            <div className="header-transparent-with-topbar">                   
                <Myheader btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
            </div>
        {/* Header */} 
    <section className="height-900 bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-sky.png)`}}>
        <div className="main-content">
   
                <div className="rwt-contact-area rn-section-contpadding">
                    <div className="container">
                            <ContactOne />
                    </div>
                </div>
        </div>
    </section>      
    </div>
{/* End Contact Area 1 */}



{/* Start Contact Area 2 */}
    <div className="section">
        <section className="wh rn-section-formpadding">
        <div className="container">
            <div className="row ">
                <div className="col-lg-7">
                    <Innerform />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </div>
        </section>
    </div>
{/* End Contact Area 2 */}




{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}


          </ReactFullpage.Wrapper>
        );
      }}
    />
  );


export default Contact;
