import React from 'react';
import SEO from "../common/SEO";

import Myheader from '../common/header/Myheader';
import Myfooter from '../common/footer/Myfooter';
import Footernews from '../components/myimagebox/Footernews';

import ReactFullpage from '@fullpage/react-fullpage';
import CounterUpFour from '../elements/counterup/CounterUpFour';

import Yellowsection from '../components/myimagebox/Yellowsection';


const About = () => (
    <ReactFullpage
      //fullpage options
      licenseKey = {'YOUR_KEY_HERE'}
      scrollingSpeed = {800} /* Options here */
  
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>


{/* Start internal banner */}
    <div className="section dispmobile">
        {/* Header  */}
        <SEO title="About Us" />            
            <div className="header-transparent-with-topbar">                   
                <Myheader btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
            </div>
        {/* Header */} 
        <div className="slider-area slider-style-about bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-sky.png)`}}>
                <div className="container pb-60">
                    <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <h3 className="title textcenter">About American Smart Trucking</h3>
                            </div>
                            <div className="col-lg-6 col-lg-6">
                                <p className="abtpara textcenter">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci.</p>
                                <p class="see textcenter">See Our Timeline</p>
                            </div>                           
                    </div>
                </div>
                <img className="w-100" src=".//images/ast/about2.png" alt="Solution"/>                
        </div>       
    </div>
{/* End internal banner */}


{/* Start Elements Area 1 */}
    <div className="section">   
        <section className="wh rn-section-tbpadding">
            <div className="container mx-auto">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                    <h3 className="title">Ready to make Legacy</h3>
                       <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
                        <p class="description">Based in Washington, USA, AST aims to create a reliable marketplace for carriers and provide transportation solutions for the leading supply chains. In order to do that you need a service provider that delivers reliably and is also cost effective.s</p>
                        </div>
                       <div class="buttn">
                          <a class="button3" href="#">Driver Jobs</a>
                          <a class="button3" href="#">Get a Quote</a>
                          <a class="button3" href="#">Contact Us</a>    
                        </div>
               </div>              
            </div>
            </div>
        </section>
    </div>
{/* End Elements Area 1 */}



{/* Start Elements Area 2 */}
    <div className="section">
    <section className='bl rn-section-integrity'>
        <div className="container">
        <div className="row">           

    <div className="col-md-4 text-center">
        <div class="integrity">
            <img src=".//images/bg/integrity.png"/>
            </div>    
        <h4 className='titley'>Integrity</h4>
        <p className="descriptionwh">We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    <div className="col-md-4 text-center">
        <div class="integrity">
          <img src=".//images/bg/transparency.png"/>
        </div>
        <h4 className='titley'>Transparency</h4>
        <p className="descriptionwh">We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    <div className="col-md-4 text-center">
        <div class="integrity">
           <img src=".//images/bg/innovative.png"/>
           </div>
           <h4 className='titley'>Innovation</h4>
        <p className="descriptionwh">We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
    </div>

    </div>
    </div>
    </section>   
    </div>
{/* End Elements Area 2 */}


{/* Start Elements Area 3 */}
    <div className="section">
        <section className='wh rn-section-tbpadding'>
        <div className="container">
        <div className="row">           
            <div className="col-lg-6 col-md-7 col-sm-12">
                <h3 className='title textcenter'>Our Mission</h3>
                <p className="description textcenter">We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Machine learning and data-driven insights to improve transportation. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation.</p>
            </div>
            <div className="col-lg-6 col-md-5 col-sm-12 text-center">
                 <img className="w-80" src=".//images/ast/mission.jpg" alt="Solution"/>
            </div>
        </div>
        </div>
        </section>
    </div>

    <div className="section">
        <section className='wh rn-section-tbpadding'>
        <div className="container">
        <div className="row reversesection">   
        <div className="col-md-6 col-sm-12 text-center">
            <img className="w-80" src=".//images/ast/how-img.jpg" alt="Solution"/> 
            </div>        
            <div className="col-md-6">
                <h3 className='title textcenter'>Our Vission</h3>
                <p className="description textcenter">We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation machine learning and data-driven insights to improve transportation.</p>
            </div>
        </div>
        </div>
        </section>
    </div>

    <div className="section">
        <section className='wh rn-section-tbpadding'>
        <div className="container">
        <div className="row">           
            <div className="col-md-6">
                <h3 className='title textcenter'>Our Passion</h3>
                <p className="description textcenter">We’re leaders in transportation technology. For a decade, we’ve invested in cutting-edge technology to get the job done efficiently at the right price every time. Our technology gives shippers and drivers full visibility into the shipping experience and uses machine learning and data-driven insights to improve transportation machine learning and data-driven insights to improve transportation.</p>
            </div>
            <div className="col-md-6">
                <img className="w-80" src=".//images/ast/passion.jpg" alt="Solution"/>
            </div>
        </div>
        </div>
        </section>
    </div>
{/* End Elements Area 3 */}


{/* Start Elements Area 4 */}
    <div className="section fp-auto-height">
        <Yellowsection />
    </div>
{/* End Elements Area 4 */}




{/* Footer */}
    <div className="section">
        <Footernews />
        <Myfooter />
    </div>
{/* Footer */}



          </ReactFullpage.Wrapper>
        );
      }}
    />
  );

export default About;
